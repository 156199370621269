/** @jsx jsx **/
import { BaseStyles, Container, jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import Layout from '~/components/layout'
import SanitizeHtml from '~/components/sanitize-html'
import { ContentFieldsType } from '~/@types/models'

interface Props {
  data: {
    page: {
      id: string
      frontmatter: {
        title: string
        customCss?: {
          h1?: {
            mb?: string
          }
        }
      }
      html: string
      fields: ContentFieldsType
    }
  }
}

export const basicPageQuery = graphql`
  query basicPageQuery($slug: String) {
    page: markdownRemark(fields: { sourceName: { eq: "basicPages" }, slug: { eq: $slug } }) {
      frontmatter {
        title
        customCss {
          h1 {
            mb
          }
        }
      }
      html
      ...markdownFields
    }
  }
`

const Page = ({ data: { page } }: Props) => {
  const { title, customCss } = page.frontmatter
  const { html: body } = page

  return (
    <Layout headerVariant="default" title={title} description={title}>
      <Container
        variant="containerPrimary"
        sx={{
          maxWidth: '1200px',
          mt: '50px',
          mb: '115px',
        }}
      >
        <BaseStyles
          sx={{
            h1: {
              textTransform: 'uppercase',
              mb: !customCss?.h1?.mb ? '50px' : customCss?.h1?.mb,
            },
            p: {
              fontSize: '16px',
              mb: '23px',
              fontWeight: 'normal',
              '&:nth-child(94) ': {
                ml: '70px',
                mt: '40px',
                mb: '40px',
              },
            },
            a: {
              color: '#666',
            },
            ol: {
              fontWeight: 'normal',
            },
            ul: {
              variant: 'variants.list.secondary',
            },
          }}
        >
          <h1>{title}</h1>
          <SanitizeHtml content={body} />
        </BaseStyles>
      </Container>
    </Layout>
  )
}

export default Page
