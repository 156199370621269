/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import sanitize from 'sanitize-html'

type Props = {
  content: string
  options?: {}
}

const SanitizeHtml: FC<Props> = ({ content, options }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(content, options),
      }}
    />
  )
}

export default SanitizeHtml
